<template>
  <div class="stageline-pdf-viewer-component">
    <div class="pdf-container">
      <div class="pdf-viewer" :class="showPagination ? 'review-pdf-viewer' : ''">
        <!-- Has to render before the image if overlay is implemented -->
        <ct-centered-spinner v-if="!pageIsLoaded(pageUrl)" />

        <img
          :class="showPagination ? 'review-document-image' : 'default-document-image'"
          :src="pageUrl"
          alt="legal document"
          @load="setPageLoaded(pageUrl)"
        >

        <template v-if="pageIsLoaded(pageUrl)">
          <div
            v-for="field in currentPagePdfFields"
            :key="field.id"
            class="pdf-field"
            :style="styleForField(field)"
          >
            <img
              v-if="signatureField(field)"
              v-show="!!formData[field.id]"
              :ref="field.id"
              :class="{ 'active-field': currentField?.id === field.id }"
              :src="formData[field.id]"
              alt="signature"
              @focus="setCurrentFieldById(field.id)"
              @error="hideImage"
            >
            <validation-provider
              v-else
              :ref="`validation-provider-${field.id}`"
              v-slot="validationContext"
              :rules="validationRules(field)"
              :name="field.name"
            >
              <b-form-radio
                v-if="field.meta?.type === 'radio'"
                :ref="field.id"
                v-model="formData[field.id]"
                readonly
                :disabled="true"
                :state="validationState(validationContext)"
                :value="field.data.button_value"
                :checked="formData[field.id] === field.data.button_value"
                class="tab-order-pdf-radio"
                :class="{ 'active-field': currentField?.id === field.id }"
                :tabindex="field.tabIndex"
                @focus="setCurrentFieldById(field.id)"
              />
              <b-form-checkbox
                v-else-if="field.meta?.type === 'checkbox'"
                :ref="field.id"
                v-model="formData[field.id]"
                readonly
                :disabled="true"
                :state="validationState(validationContext)"
                value="true"
                unchecked-value="false"
                class="tab-order-pdf-checkbox"
                :class="{ 'active-field': currentField?.id === field.id }"
                :tabindex="field.tabIndex"
                @focus="setCurrentFieldById(field.id)"
              />
              <b-form-input
                v-else-if="initialContributionField(field)"
                :ref="field.id"
                v-model="computedInitialContributions"
                readonly
                :disabled="true"
                type="number"
                class="input"
                :class="{ 'active-field': currentField?.id === field.id }"
                :tabindex="field.tabIndex"
                @focus="setCurrentFieldById(field.id)"
              />
              <b-form-input
                v-else-if="sumField(field)"
                :ref="field.id"
                :value="computedGtSum(field)"
                readonly
                :disabled="true"
                type="number"
                class="input"
                :class="{ 'active-field': currentField?.id === field.id }"
                :tabindex="field.tabIndex"
                @focus="setCurrentFieldById(field.id)"
              />
              <b-form-input
                v-else-if="field.meta?.type === 'number'"
                :ref="field.id"
                v-model="formData[field.id]"
                readonly
                :disabled="isDisabled(field)"
                :state="validationState(validationContext)"
                type="number"
                class="input"
                :class="{ 'active-field': currentField?.id === field.id }"
                :tabindex="field.tabIndex"
                @focus="setCurrentFieldById(field.id)"
              />
              <b-form-input
                v-else-if="field.meta?.type === 'date' && !field.data?.parts?.length"
                :ref="field.id"
                v-model="formData[field.id]"
                readonly
                :disabled="isDisabled(field)"
                :state="validationState(validationContext)"
                type="date"
                class="input"
                :class="{ 'active-field': currentField?.id === field.id }"
                :tabindex="field.tabIndex"
                @focus="setCurrentFieldById(field.id)"
              />
              <b-form-input
                v-else-if="phoneField(field)"
                :ref="field.id"
                v-model="formData[field.id]"
                readonly
                :disabled="isDisabled(field)"
                :state="validationState(validationContext)"
                class="input"
                :class="{ 'active-field': currentField?.id === field.id }"
                :tabindex="field.tabIndex"
                @focus="setCurrentFieldById(field.id)"
                @keypress="isAcceptablePhoneChar($event)"
              />
              <b-form-textarea
                v-else-if="field.meta?.type === 'multiline_text' || isMultilineTextfield(field)"
                :ref="field.id"
                v-model="formData[field.id]"
                readonly
                no-resize
                :state="validationState(validationContext)"
                :field-id="field.id"
                :disabled="isDisabled(field)"
                class="input text-area-input"
                :class="{ 'active-field': currentField?.id === field.id }"
                :tabindex="field.tabIndex"
                @focus="setCurrentFieldById(field.id)"
              />
              <b-form-input
                v-else
                :ref="field.id"
                v-model="formData[field.id]"
                readonly
                :state="validationState(validationContext)"
                :field-id="field.id"
                :disabled="isDisabled(field)"
                class="input"
                :class="{ 'active-field': currentField?.id === field.id }"
                :tabindex="field.tabIndex"
                @focus="setCurrentFieldById(field.id)"
              />
            </validation-provider>
          </div>
        </template>
      </div>
      <b-pagination
        v-if="totalPages && showPagination"
        v-model="curPage"
        align="center"
        class="custom-pagination"
        :current-page.sync="currentPage"
        :total-rows="totalPages"
        :per-page="1"
        @change="currentPageUpdated($event)"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { getFieldSuggestionValue } from '@/components/StagelineV2/schemaForm/pdf/helper'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'StagelinePdfViewer',
  components: {
    CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner'),
    ValidationProvider,
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
    showPagination: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadedPages: [],
      paginationKey: 0,
      curPage: this.currentPage,
    }
  },
  computed: {
    ...mapGetters('stageline', [
      'company',
      'isFinalizeLegalDocs',
      'raServiceInCart',
    ]),
    ...mapGetters('stagelineSchemaForm', [
      'currentField',
      'currentPage',
      'currentPagePdfFields',
      'formData',
      'isDisabledField',
      'pdfAddressCheckboxState',
      'resource',
      'resourceFields',
      'validationRules',
      'validationState',
    ]),
    ...mapGetters('checkout', [
      'formationProductInCart',
    ]),
    ...mapGetters('companies', [
      'activeRAServices',
    ]),
    pageUrl() {
      return this.resource?.page_urls?.[this.currentPage - 1]
    },
    pages() {
      return this.resource?.pages
    },
    totalPages() {
      return this.pages?.length
    },
    fieldsWithSuggestions() {
      return this.resourceFields.length ?
        this.resourceFields.filter(rf => rf?.meta?.suggestion && rf.type === 'object') :
        []
    },
    isDisabled() {
      return field => this.isDisabledField(field) ||
        (this.disableRaFieldsIfRaService(field) && this.useOurAddressChecked(field)) ||
        this.disableAllFields
    },
    disableRaFieldsIfRaService() {
      return field => this.hasActiveRaServiceOrInCart(field)
    },
    useOurAddressChecked() {
      return field => this.pdfAddressCheckboxState[field.id]
    },
    disableAllFields() {
      return this.readOnly
    },
    distinctCapitalContributions() {
      return Array.from(
        new Set(
          this.resourceFields
            .filter(field => {
              return (
                field.meta.type === 'contributions' &&
                field.data?.parts &&
                field.data?.parts[0] === 'capital_contributions.amount'
              )
            })
            .map(field => field.id)
        )
      )
    },
    computedInitialContributions() {
      const capitalContributionIds = this.distinctCapitalContributions
      let totalSum = 0

      for (const id of capitalContributionIds) {
        if (this.formData.hasOwnProperty(id)) {
          const value = parseFloat(this.formData[id])
          if (!isNaN(value) && value > 0) totalSum += value
        }
      }

      return totalSum
    },

  },
  watch: {
    async currentField(newField, oldField) {
      if (newField !== oldField) {
        if (newField?.id) {
          const validationProviderRef = `validation-provider-${newField.id}`
          const validationProvider = this.$refs[validationProviderRef]?.[0]
          if (validationProvider) {
            // Manually validate the field in the PDF Viewer to be in sync with that in the
            // PDF Schema Form
            await validationProvider.validate()
          }

          this.scrollToActiveField()
        }
      }
    },
    currentPage(newValue, oldValue) {
      if (newValue !== oldValue && newValue !== this.curPage) this.curPage = newValue
    },
  },
  async mounted() {
    this.curPage = this.currentPage
    await this.preFillFields()
    this.$emit('loaded')
  },
  methods: {
    ...mapActions('stagelineSchemaForm', [
      'loadResourceFieldsForCompany',
      'setCurrentFieldById',
      'setCurrentPage',
      'setFormData',
      'setCurrentFieldToInitialPageField',
    ]),
    sumField(field) {
      if (!field?.data?.parts || !field.data.parts.length) return false

      const part = field?.data?.parts[0]
      let partGTField = field

      if (part) {
        const nestedProperties = part.split('.')
        for (const propName of nestedProperties) {
          partGTField = partGTField.fields.find(f => f.name === propName) || partGTField
        }
      }

      return field?.meta?.type === 'object' && partGTField?.meta?.type === 'sum'
    },
    computedGtSum(field) {
      const sum = this.resourceFields
        .filter(resourceField =>
          resourceField?.glossary_term_name === field?.glossary_term_name &&
          resourceField.data?.parts &&
          resourceField.data.parts[0]?.toLowerCase().includes('amount')
        )
        .reduce((totalSum, resourceField) => {
          const fieldValue = parseFloat(this.formData[resourceField.id]) || 0
          return totalSum + fieldValue
        }, 0)
      if (this.formData[field.id] !== sum) {
        const newFormData = this.formData
        newFormData[field.id] = sum
        this.setFormData(newFormData)
      }
      return sum
    },
    signatureField(field) {
      return field?.data?.parts?.includes('signature') ||
        field.type === 'signature' ||
        field.field_type === 'signature'
    },
    isMultilineTextfield(field) {
      const standard_row_height_conversion = 0.018
      return field?.height >= (2 * standard_row_height_conversion)
    },
    phoneField(field) {
      return field?.meta?.type === 'phone' ||
        (field?.data?.parts?.includes('phone_number'))
    },
    initialContributionField(field) {
      return field?.meta?.type === 'contributions' &&
        field?.data?.parts?.includes('initial_contributions')
    },
    async preFillFields() {
      await this.loadResourceFieldsForCompany({
        companyId: this.company.id,
        resourceId: this.resource.id,
      })

      // Use whatever we get back from resource fields request
      if (this.isFinalizeLegalDocs) return
      this.fieldsWithSuggestions.forEach(field => {
        if (field?.data?.parts?.length) {

          // Only prefill RA and Filer fields on load
          const prefillGlossaryTerms = ['Registered Agent', 'Filer']
          const useHint = this.useHint(field)
          const suggestion = getFieldSuggestionValue(field)
          const shouldPrefillField = prefillGlossaryTerms.includes(field?.glossary_term_name)

          if (useHint && suggestion && shouldPrefillField) {
            this.resourceFields.find(f => f.id === field.id).data.disabled = true
            this.formData[field.id] = suggestion
          } else if ((this.formData[field.id] === suggestion) && !useHint) {
            this.resourceFields.find(f => f.id === field.id).data.disabled = false
            delete this.formData[field.id]
          }
        }
      })
    },
    useHint(field) {
      return (
        field?.useHint ||
        this.hasActiveRaServiceOrInCart(field) ||
        this.hasFilingProductInCart(field)
      )
    },
    setPageLoaded(pageUrl) {
      this.loadedPages.push(pageUrl)
    },
    pageIsLoaded(pageUrl) {
      return this.loadedPages.includes(pageUrl)
    },
    styleForField(field) {
      return {
        top:    `${field.y * 100}%`,
        left:   `${field.x * 100}%`,
        width:  `${field.width * 100}%`,
        height: `${field.height * 100}%`,
      }
    },
    scrollToActiveField() {
      this.$nextTick(() => {
        const el = this.$el.querySelector('.active-field')
          el?.scrollIntoView({ behavior: 'smooth', block: 'center' })
      })
    },
    hasActiveRaServiceOrInCart(field) {
      const productKind = field?.meta?.suggestion?.context?.product_kind
      if (productKind === 'registered_agent_product') return this.checkForRAService()
      return false
    },
    hasFilingProductInCart(field) {
      const productKind = field?.meta?.suggestion?.context?.product_kind
      if (productKind !== 'filing_product') return false
      return this.formationProductInCart && this.checkForRAService()
    },
    checkForRAService() {
      return this.raServiceInCart || !!this.activeRAServices.length
    },
    isAcceptablePhoneChar(e) {
      !e.key.match(/[\d]/g) ? e.preventDefault() : true
    },
    async currentPageUpdated(page) {
      await this.setCurrentPage(page)
      await this.setCurrentFieldToInitialPageField(page)
    },
    hideImage(event) {
      event.target.style.visibility = "hidden"
    },
  },
}
</script>

<style lang="scss" scoped>
$gray3-light1: #F4F5F5;
$gray2-light2: #D3D3D3;

.stageline-pdf-viewer-component {
  display: flex;
  flex-direction: column;

  .pdf-container {
    display: flex;
    flex-direction: column;
    height: auto;
    background: $gray3-light1;
    border: 1px solid $gray2-light2;
    border-radius: 0.625em;

    ::v-deep .custom-pagination {
      position: relative !important;
      width: 100% !important;
      height: unset !important;
      padding: 0 1em 0 1em;

      .page-item.active .page-link {
        background-color: $ct-ui-primary !important;
      }

      .page-item:not(.active) .page-link {
        color: $ct-ui-primary !important;
      }
    }
    .pdf-viewer {
      height: 100%;
      position: relative;
      margin: 1.0em;

      &.review-pdf-viewer {
        height: 50vh !important;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      }

      .default-document-image {
        z-index: 1;
        width: 100%;
        max-height: 70vh;
      }

      .review-document-image {
        height: 100%;
        margin-top: 0 !important;
        width: auto;
      }

      .pdf-field {
        display: flex;
        position: absolute;
        mix-blend-mode: multiply;

        .text-area-input {
          overflow: hidden !important;
        }
        .input:hover{
          cursor: pointer;
        }
        input[disabled='disabled'] {
          background-color: #f4f4f4;
          background-image: unset !important;
        }
        .form-control {
          &.active-field,
          &:focus {
            background-color: #becbff;
            box-shadow: 0 0 2px 3px #becbff;
          }
          font-family: monospace, 'Consolas', 'Inconsolata', 'Monaco', 'Courier';
          font-size: 0.563rem;
          font-weight: 700;
          position: absolute;
          width: 100%;
          height: 100% !important;
          min-height: unset !important;
          border: none;
          border: 1px transparent;
          bottom: 0;
          padding: 0 0.25em;
          border-radius: 0.188em;
          //background-color: #dde4ff;
          &.is-invalid {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' fill-opacity='0.7' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' stroke-opacity='0.7' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
            //background-color: rgba(243, 6, 2, 0.13);
          }
        }
        .custom-radio {
          padding-left: 1.25em;
          bottom: 0.5em;
          ::before, ::after {
            cursor: pointer;
          }
        }
        .custom-checkbox {
          bottom: 0.063em;
          left: 0.063em;
          ::before, ::after {
            cursor: pointer;
          }
        }
        img {
          position: absolute;
          height: 100%;
          width: auto;
        }
      }
    }
  }
}
</style>
