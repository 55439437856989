var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "stageline-pdf-viewer-component" }, [
    _c(
      "div",
      { staticClass: "pdf-container" },
      [
        _c(
          "div",
          {
            staticClass: "pdf-viewer",
            class: _vm.showPagination ? "review-pdf-viewer" : "",
          },
          [
            !_vm.pageIsLoaded(_vm.pageUrl)
              ? _c("ct-centered-spinner")
              : _vm._e(),
            _c("img", {
              class: _vm.showPagination
                ? "review-document-image"
                : "default-document-image",
              attrs: { src: _vm.pageUrl, alt: "legal document" },
              on: {
                load: function ($event) {
                  return _vm.setPageLoaded(_vm.pageUrl)
                },
              },
            }),
            _vm.pageIsLoaded(_vm.pageUrl)
              ? _vm._l(_vm.currentPagePdfFields, function (field) {
                  return _c(
                    "div",
                    {
                      key: field.id,
                      staticClass: "pdf-field",
                      style: _vm.styleForField(field),
                    },
                    [
                      _vm.signatureField(field)
                        ? _c("img", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !!_vm.formData[field.id],
                                expression: "!!formData[field.id]",
                              },
                            ],
                            ref: field.id,
                            refInFor: true,
                            class: {
                              "active-field": _vm.currentField?.id === field.id,
                            },
                            attrs: {
                              src: _vm.formData[field.id],
                              alt: "signature",
                            },
                            on: {
                              focus: function ($event) {
                                return _vm.setCurrentFieldById(field.id)
                              },
                              error: _vm.hideImage,
                            },
                          })
                        : _c("validation-provider", {
                            ref: `validation-provider-${field.id}`,
                            refInFor: true,
                            attrs: {
                              rules: _vm.validationRules(field),
                              name: field.name,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (validationContext) {
                                    return [
                                      field.meta?.type === "radio"
                                        ? _c("b-form-radio", {
                                            ref: field.id,
                                            refInFor: true,
                                            staticClass: "tab-order-pdf-radio",
                                            class: {
                                              "active-field":
                                                _vm.currentField?.id ===
                                                field.id,
                                            },
                                            attrs: {
                                              readonly: "",
                                              disabled: true,
                                              state:
                                                _vm.validationState(
                                                  validationContext
                                                ),
                                              value: field.data.button_value,
                                              checked:
                                                _vm.formData[field.id] ===
                                                field.data.button_value,
                                              tabindex: field.tabIndex,
                                            },
                                            on: {
                                              focus: function ($event) {
                                                return _vm.setCurrentFieldById(
                                                  field.id
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.formData[field.id],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formData,
                                                  field.id,
                                                  $$v
                                                )
                                              },
                                              expression: "formData[field.id]",
                                            },
                                          })
                                        : field.meta?.type === "checkbox"
                                        ? _c("b-form-checkbox", {
                                            ref: field.id,
                                            refInFor: true,
                                            staticClass:
                                              "tab-order-pdf-checkbox",
                                            class: {
                                              "active-field":
                                                _vm.currentField?.id ===
                                                field.id,
                                            },
                                            attrs: {
                                              readonly: "",
                                              disabled: true,
                                              state:
                                                _vm.validationState(
                                                  validationContext
                                                ),
                                              value: "true",
                                              "unchecked-value": "false",
                                              tabindex: field.tabIndex,
                                            },
                                            on: {
                                              focus: function ($event) {
                                                return _vm.setCurrentFieldById(
                                                  field.id
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.formData[field.id],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formData,
                                                  field.id,
                                                  $$v
                                                )
                                              },
                                              expression: "formData[field.id]",
                                            },
                                          })
                                        : _vm.initialContributionField(field)
                                        ? _c("b-form-input", {
                                            ref: field.id,
                                            refInFor: true,
                                            staticClass: "input",
                                            class: {
                                              "active-field":
                                                _vm.currentField?.id ===
                                                field.id,
                                            },
                                            attrs: {
                                              readonly: "",
                                              disabled: true,
                                              type: "number",
                                              tabindex: field.tabIndex,
                                            },
                                            on: {
                                              focus: function ($event) {
                                                return _vm.setCurrentFieldById(
                                                  field.id
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.computedInitialContributions,
                                              callback: function ($$v) {
                                                _vm.computedInitialContributions =
                                                  $$v
                                              },
                                              expression:
                                                "computedInitialContributions",
                                            },
                                          })
                                        : _vm.sumField(field)
                                        ? _c("b-form-input", {
                                            ref: field.id,
                                            refInFor: true,
                                            staticClass: "input",
                                            class: {
                                              "active-field":
                                                _vm.currentField?.id ===
                                                field.id,
                                            },
                                            attrs: {
                                              value: _vm.computedGtSum(field),
                                              readonly: "",
                                              disabled: true,
                                              type: "number",
                                              tabindex: field.tabIndex,
                                            },
                                            on: {
                                              focus: function ($event) {
                                                return _vm.setCurrentFieldById(
                                                  field.id
                                                )
                                              },
                                            },
                                          })
                                        : field.meta?.type === "number"
                                        ? _c("b-form-input", {
                                            ref: field.id,
                                            refInFor: true,
                                            staticClass: "input",
                                            class: {
                                              "active-field":
                                                _vm.currentField?.id ===
                                                field.id,
                                            },
                                            attrs: {
                                              readonly: "",
                                              disabled: _vm.isDisabled(field),
                                              state:
                                                _vm.validationState(
                                                  validationContext
                                                ),
                                              type: "number",
                                              tabindex: field.tabIndex,
                                            },
                                            on: {
                                              focus: function ($event) {
                                                return _vm.setCurrentFieldById(
                                                  field.id
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.formData[field.id],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formData,
                                                  field.id,
                                                  $$v
                                                )
                                              },
                                              expression: "formData[field.id]",
                                            },
                                          })
                                        : field.meta?.type === "date" &&
                                          !field.data?.parts?.length
                                        ? _c("b-form-input", {
                                            ref: field.id,
                                            refInFor: true,
                                            staticClass: "input",
                                            class: {
                                              "active-field":
                                                _vm.currentField?.id ===
                                                field.id,
                                            },
                                            attrs: {
                                              readonly: "",
                                              disabled: _vm.isDisabled(field),
                                              state:
                                                _vm.validationState(
                                                  validationContext
                                                ),
                                              type: "date",
                                              tabindex: field.tabIndex,
                                            },
                                            on: {
                                              focus: function ($event) {
                                                return _vm.setCurrentFieldById(
                                                  field.id
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.formData[field.id],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formData,
                                                  field.id,
                                                  $$v
                                                )
                                              },
                                              expression: "formData[field.id]",
                                            },
                                          })
                                        : _vm.phoneField(field)
                                        ? _c("b-form-input", {
                                            ref: field.id,
                                            refInFor: true,
                                            staticClass: "input",
                                            class: {
                                              "active-field":
                                                _vm.currentField?.id ===
                                                field.id,
                                            },
                                            attrs: {
                                              readonly: "",
                                              disabled: _vm.isDisabled(field),
                                              state:
                                                _vm.validationState(
                                                  validationContext
                                                ),
                                              tabindex: field.tabIndex,
                                            },
                                            on: {
                                              focus: function ($event) {
                                                return _vm.setCurrentFieldById(
                                                  field.id
                                                )
                                              },
                                              keypress: function ($event) {
                                                return _vm.isAcceptablePhoneChar(
                                                  $event
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.formData[field.id],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formData,
                                                  field.id,
                                                  $$v
                                                )
                                              },
                                              expression: "formData[field.id]",
                                            },
                                          })
                                        : field.meta?.type ===
                                            "multiline_text" ||
                                          _vm.isMultilineTextfield(field)
                                        ? _c("b-form-textarea", {
                                            ref: field.id,
                                            refInFor: true,
                                            staticClass:
                                              "input text-area-input",
                                            class: {
                                              "active-field":
                                                _vm.currentField?.id ===
                                                field.id,
                                            },
                                            attrs: {
                                              readonly: "",
                                              "no-resize": "",
                                              state:
                                                _vm.validationState(
                                                  validationContext
                                                ),
                                              "field-id": field.id,
                                              disabled: _vm.isDisabled(field),
                                              tabindex: field.tabIndex,
                                            },
                                            on: {
                                              focus: function ($event) {
                                                return _vm.setCurrentFieldById(
                                                  field.id
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.formData[field.id],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formData,
                                                  field.id,
                                                  $$v
                                                )
                                              },
                                              expression: "formData[field.id]",
                                            },
                                          })
                                        : _c("b-form-input", {
                                            ref: field.id,
                                            refInFor: true,
                                            staticClass: "input",
                                            class: {
                                              "active-field":
                                                _vm.currentField?.id ===
                                                field.id,
                                            },
                                            attrs: {
                                              readonly: "",
                                              state:
                                                _vm.validationState(
                                                  validationContext
                                                ),
                                              "field-id": field.id,
                                              disabled: _vm.isDisabled(field),
                                              tabindex: field.tabIndex,
                                            },
                                            on: {
                                              focus: function ($event) {
                                                return _vm.setCurrentFieldById(
                                                  field.id
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.formData[field.id],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formData,
                                                  field.id,
                                                  $$v
                                                )
                                              },
                                              expression: "formData[field.id]",
                                            },
                                          }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                    ],
                    1
                  )
                })
              : _vm._e(),
          ],
          2
        ),
        _vm.totalPages && _vm.showPagination
          ? _c("b-pagination", {
              staticClass: "custom-pagination",
              attrs: {
                align: "center",
                "current-page": _vm.currentPage,
                "total-rows": _vm.totalPages,
                "per-page": 1,
              },
              on: {
                "update:currentPage": function ($event) {
                  _vm.currentPage = $event
                },
                "update:current-page": function ($event) {
                  _vm.currentPage = $event
                },
                change: function ($event) {
                  return _vm.currentPageUpdated($event)
                },
              },
              model: {
                value: _vm.curPage,
                callback: function ($$v) {
                  _vm.curPage = $$v
                },
                expression: "curPage",
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }